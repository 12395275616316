import { lazy } from 'react';

export const RoutesMakeup = [
    {
        exact: true,
        path: "/",
        component:  lazy(() => import('./Makeup/pages/Home'))
    },
    {
        exact: true,
        path: "/about",
        component:  lazy(() => import('./Makeup/pages/About'))
    },
    {
        exact: true,
        path: '/gallery',
        component:  lazy(() => import('./Makeup/pages/Gallery'))
    },
    {
        exact: true,
        path: '/faq',
        component:  lazy(() => import('./Makeup/pages/FAQ'))
    },
    {
        exact: true,
        path: '/get-a-quote',
        component:  lazy(() => import('./Makeup/pages/GetAQuote'))
    },
    {
        exact: true,
        path: '/contact',
        component:  lazy(() => import('./Makeup/pages/Contacts'))
    },
    {
        exact: true,
        path: '/services/stretch-marks-camouflage',
        component:  lazy(() => import('./Makeup/pages/Services/StretchMarksCamouflage'))
    },
    {
        exact: true,
        path: '/services/scars-camouflage',
        component:  lazy(() => import('./Makeup/pages/Services/ScarsCamouflage'))
    },
    {
        exact: true,
        path: '/services/vitiligo-camouflage',
        component:  lazy(() => import('./Makeup/pages/Services/VitiligoCamouflage'))
    },
];
